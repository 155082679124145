import React from 'react'
import { css } from '@styled-system/css'

import {
  Box,
  Caption,
  Paragraph,
  ProgressIndicator,
  P,
  H2,
  H1,
} from '@allied-solutions/affinity'

import {
  useMustAttachLogic,
  useStore,
  useSubmitInsuranceLogic,
} from '../../../hooks'
import {
  AppLayout,
  BackButton,
  ButtonGroup,
  FileUploader,
  InfoBox,
  NextButton,
  SubmitInsuranceLayout,
} from '../../../components'

const UploadDocuments = ({ location }) => {
  const { dispatch, state } = useStore()
  const { pages } = useSubmitInsuranceLogic(location)

  const { handleSubmit, isValid } = useMustAttachLogic(location)

  return (
    <SubmitInsuranceLayout>
      <form onSubmit={handleSubmit}>
        <AppLayout
          leftSide={
            <>
              <Paragraph
                mb={6}
                typeStyle="labelLarge"
                textAlign="left"
                color="gray600"
              >
                Upload Files
              </Paragraph>
              <H1 className="h6" typeStyle="h6">
                Upload your insurance documents.
              </H1>
              <Paragraph
                typeStyle="bodyMedium"
                textAlign="left"
                color="gray600"
              >
                If you have a copy of your insurance policy documents, you can
                upload them here.
              </Paragraph>
              <Paragraph
                typeStyle="bodyMedium"
                textAlign="left"
                color="gray600"
              >
                This is a required step and will speed up the verification
                process.
              </Paragraph>
            </>
          }
          rightSide={
            <>
              <InfoBox>
                <H2
                  className="titleSmall"
                  typeStyle="titleSmall"
                  fontWeight={900}
                  textAlign="left"
                  css={css({ pb: 6 })}
                >
                  Upload Documents
                </H2>

                <FileUploader
                  files={state?.form?.files || []}
                  onChange={files => {
                    dispatch({
                      type: 'addToState',
                      payload: { form: { ...state.form, files: files } },
                    })
                  }}
                >
                  <FileUploader.Control
                    id="fileUpload"
                    accept="application/pdf, image/tiff, image/jpeg"
                    multiple
                  />
                  {!!state?.form?.files?.length && (
                    <Box>
                      <P
                        typeStyle="label"
                        textAlign="left"
                        mb={4}
                        mt={7}
                        display="block"
                      >
                        Selected files
                      </P>
                      <FileUploader.FilesList />
                    </Box>
                  )}
                  {!isValid && (
                    <Caption display="flex" color="error">
                      Please attach at least one file.
                    </Caption>
                  )}
                </FileUploader>
              </InfoBox>
            </>
          }
          footerButtons={
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <ProgressIndicator
                pages={pages}
                currentPage={location.pathname}
              />
              <ButtonGroup display="flex" justifyContent="flex-end">
                <BackButton />
                <NextButton
                  // spoof Formik props
                  formikProps={{
                    isSubmitting: false,
                    isValid,
                  }}
                />
              </ButtonGroup>
            </Box>
          }
        />
      </form>
    </SubmitInsuranceLayout>
  )
}

export default UploadDocuments
